const ALLOWED_MPP_PATHS = [
  'products/1799/product-catalog/bestsellers',
  'products/708/product-catalog/whats-new',
  'products/19045/product-catalog/sets-gifts',
  'products/707/product-catalog/sets-gifts/skin-care-sets-gifts',
  'products/653/product-catalog/sets-gifts/makeup-sets-gifts',
  'products/630/product-catalog/sets-gifts/fragrance-sets-gifts',
  'products/26450/product-catalog/best-sellers/skin-care-best-sellers',
  'products/571/product-catalog/fragrance',
  'products/26451/product-catalog/best-sellers/makeup-best-sellers',
  'products/684/product-catalog/skin-care/cleanser-makeup-remover',
  'products/26389/product-catalog/skin-care/toner-treatment-lotion',
  'products/26396/product-catalog/skin-care/skin-care-collections/revitalizing-supreme',
  'products/26452/product-catalog/best-sellers/fragrance-best-sellers',
  'products/27655/product-catalog/sets-gifts/limited-edition-gifts',
  'products/26459/product-catalog/gifts/gifts-under-50',
  'products/27656/product-catalog/sets-gifts/most-wanted-gifts',
  'products/26460/product-catalog/gifts/gifts-50-to-100',
  'products/681/product-catalog/skin-care',
  'products/631/product-catalog/makeup',
  'products/646/product-catalog/makeup/lips',
  'products/649/product-catalog/makeup/lips/lipstick',
  'products/26455/product-catalog/whats-new/new-makeup',
  'products/642/product-catalog/makeup/face/concealer',
  'products/26454/product-catalog/whats-new/new-skin-care',
  'products/14517/product-catalog/fragrance/fragrance-collections/house-of-estee-lauder',
  'products/26410/product-catalog/makeup/lips/lip-care',
  'products/9786/product-catalog/fragrance/fragrance-collections/white-linen',
  'products/637/product-catalog/makeup/eyes/mascara',
  'products/29221/landing-pages/mothers-day',
  'products/26418/product-catalog/makeup/makeup-collections/pure-color',
  'products/13093/product-catalog/fragrance/fragrance-collections/youth-dew',
  'products/634/product-catalog/makeup/eyes/brows',
  'products/647/product-catalog/makeup/lips/lip-gloss',
  'products/26926/product-catalog/skin-care/skin-care-collections/micro-essence',
  'products/28394/product-catalog/landing-pages/lunar-new-year',
  'products/9781/product-catalog/fragrance/fragrance-collections/bronze-goddess',
  'products/687/product-catalog/skin-care/masks',
  'products/12883/product-catalog/makeup/accessories/brushes-tools',
  'products/648/product-catalog/makeup/lips/lip-pencil',
  'products/1216/product-catalog/landing-pages/spf-essentials',
  'products/8257/product-catalog/fragrance/fragrance-collections/for-men',
  'products/26409/product-catalog/makeup/lips/liquid-lip',
  'products/638/product-catalog/makeup/face',
  'products/12855/product-catalog/fragrance/fragrance-collections/private-collection',
  'products/26426/product-catalog/fragrance/notes/jasmine-sandalwood',
  'products/686/product-catalog/makeup/accessories/makeup-remover',
  'products/12853/product-catalog/fragrance/fragrance-collections/pleasures',
  'products/11564/product-catalog/fragrance/fragrance-collections/modern-muse',
  'products/27120/product-catalog/fragrance/bath-body',
  'products/26400/product-catalog/skincare/by-concern/loss-of-firmness',
  'products/28466/landing-pages/valentines-day',
  'products/26401/product-catalog/skincare/by-concern/first-signs-of-aging',
  'products/26403/product-catalog/skincare/by-concern/pores',
  'products/26405/product-catalog/skincare/by-concern/dull-tired-looking-skin',
  'products/26463/product-catalog/landing-pages/wedding-edit',
  'products/26402/product-catalog/skincare/by-concern/lines-wrinkles',
  'products/633/product-catalog/makeup/eyes',
  'products/673/product-catalog/sets-gifts/luxe-sets-gifts',
  'products/26461/product-catalog/gifts/gifts-over-100',
  //'products/26431/product-catalog/fragrance/fragrance-family/ambers',
  //'products/26429/product-catalog/fragrance/fragrance-family/lush-bouquets',
  'products/26456/product-catalog/whats-new/new-fragrance',
  'products/26424/product-catalog/fragrance/notes/peony',
  //'products/26428/product-catalog/fragrance/fragrance-family/fresh-blooms',
  'products/1914/product-catalog/landing-pages/pink-ribbon',
  //'products/26430/product-catalog/fragrance/fragrance-family/warm-woods',
  'products/12882/product-catalog/makeup/accessories',
  'products/26425/product-catalog/fragrance/notes/rose',
  'products/26427/product-catalog/fragrance/notes/amber',
  'products/26435/product-catalog/aerin/aerin-fragrance-collection/ikat-jasmine',
  'products/30986/product-catalog/landing-pages/pride-month-best-sellers',
  'products/26462/product-catalog/landing-pages/the-night-shop',
  'products/32825/product-catalog/landing-pages/rebellious-rose-collection',
  'products/product-catalog/makeup/lips/shop-by-finish',
  'products/19239/product-catalog/last-chance',
  'products/26390/product-catalog/skin-care/skin-care-collections/advanced-night-repair',
  'products/688/product-catalog/skin-care/moisturizer',
  'products/689/product-catalog/skin-care/repair-serum',
  'products/26415/product-catalog/makeup/makeup-collections/double-wear',
  'products/636/product-catalog/makeup/eyes/eyeshadow',
  'products/643/product-catalog/makeup/face/foundation',
  // 'products/685/product-catalog/skin-care/eyecare'
  'products/685/product-catalog/skin-care/eye-cream-treatment',
  'products/31561/product-catalog/fragrance/fragrance-collections/luxury-fragrance-collection',
  'products/26394/product-catalog/skin-care/skin-care-collections/perfectionist',
  'products/26416/product-catalog/makeup/makeup-collections/pure-color-envy',
  'products/1473/product-catalog/makeup/face/primer',
  // 'products/26462/product-catalog/sets-gifts/custom-engraving',
  'products/26392/product-catalog/skin-care/skin-care-collections/daywear-nightwear',
  'self-care-beauty-tips',
  'products/26393/product-catalog/skin-care/skin-care-collections/nutritious',
  'products/26395/product-catalog/skin-care/skin-care-collections/resilience-multi-effect',
  'products/693/product-catalog/skin-care/targeted-treatment',
  'products/27184/product-catalog/makeup/makeup-collections/futurist'
];

const ALLOWED_SPP_PATHS = [];

export const ALLOWED_PATHS = [...ALLOWED_MPP_PATHS, ...ALLOWED_SPP_PATHS];

export const INTERNAL_TEST_PATHS = [
  'product/689/77491/product-catalog/skincare/repair-serum/advanced-night-repair-serum/synchronized-multi-recovery-complex',
  'products/12772/product-catalog/aerin/aerin-fragrance-collection',
  'products/644/product-catalog/makeup/face/powder-compacts',
  'products/33199/landing-pages/refillable-beauty-products',
  'products/26458/product-catalog/sets-gifts/custom-engraving',
  'publishing-test'
];

// Percentages represent amount being routed to Gemini
export const VULCANIZED_PATHS = {
  50: [
    'products/36779/product-catalog/makeup/makeup-collections/sabyasachi',
    'products/681/product-catalog/skin-care',
    'products/631/product-catalog/makeup',
    'products/9781/product-catalog/fragrance/fragrance-collections/bronze-goddess',
    'products/708/product-catalog/whats-new',
    'products/653/product-catalog/sets-gifts/makeup-sets-gifts',
    'products/649/product-catalog/makeup/lips/lipstick',
    'products/646/product-catalog/makeup/lips',
    'products/638/product-catalog/makeup/face',
    'products/571/product-catalog/fragrance',
    'products/26463/product-catalog/landing-pages/wedding-edit',
    'products/26459/product-catalog/gifts/gifts-under-50',
    'products/26456/product-catalog/whats-new/new-fragrance',
    'products/26455/product-catalog/whats-new/new-makeup',
    'products/26454/product-catalog/whats-new/new-skin-care',
    'products/26451/product-catalog/best-sellers/makeup-best-sellers',
    'products/26450/product-catalog/best-sellers/skin-care-best-sellers',
    'products/26418/product-catalog/makeup/makeup-collections/pure-color',
    'products/1799/product-catalog/bestsellers',
    'products/15781/product-catalog/landing-pages/trial-sizes',
    'self-care-beauty-tips',
    'products/693/product-catalog/skin-care/targeted-treatment',
    'products/689/product-catalog/skin-care/repair-serum',
    'products/688/product-catalog/skin-care/moisturizer',
    'products/685/product-catalog/skin-care/eye-cream-treatment',
    'products/644/product-catalog/makeup/face/powder-compacts',
    'products/643/product-catalog/makeup/face/foundation',
    'products/639/product-catalog/makeup/face/blush-bronzer-highlighter',
    'products/636/product-catalog/makeup/eyes/eyeshadow',
    'products/634/product-catalog/makeup/eyes/brows',
    'products/31561/product-catalog/fragrance/fragrance-collections/luxury-fragrance-collection',
    'products/28371/product-catalog/fragrance-collections/beautiful-magnolia',
    'products/27184/product-catalog/makeup/makeup-collections/futurist',
    'products/26926/product-catalog/skin-care/skin-care-collections/micro-essence',
    'products/26415/product-catalog/makeup/makeup-collections/double-wear',
    'products/26396/product-catalog/skin-care/skin-care-collections/revitalizing-supreme',
    'products/26395/product-catalog/skin-care/skin-care-collections/resilience-multi-effect',
    'products/26394/product-catalog/skin-care/skin-care-collections/perfectionist',
    'products/26393/product-catalog/skin-care/skin-care-collections/nutritious',
    'products/26392/product-catalog/skin-care/skin-care-collections/daywear-nightwear',
    'products/26390/product-catalog/skin-care/skin-care-collections/advanced-night-repair',
    'products/26389/product-catalog/skin-care/toner-treatment-lotion',
    'products/1473/product-catalog/makeup/face/primer',
    'products/34687/product-catalog/fragrance/fragrance-family/fresh-fruity',
    'products/28466/landing-pages/valentines-day',
    'products/26410/product-catalog/makeup/lips/lip-care',
    'products/11564/product-catalog/fragrance/fragrance-collections/modern-muse',
    'products/1216/product-catalog/landing-pages/spf-essentials',
    'products/12882/product-catalog/makeup/accessories',
    'products/30986/product-catalog/landing-pages/pride-month-best-sellers',
    'products/9786/product-catalog/fragrance/fragrance-collections/white-linen',
    'products/635/product-catalog/makeup/eyes/eyeliner',
    'products/8257/product-catalog/fragrance/fragrance-collections/for-men',
    'products/12855/product-catalog/fragrance/fragrance-collections/private-collection',
    'products/26422/product-catalog/fragrance/fragrance-collections/beautiful-belle',
    'products/26448/product-catalog/aerin/aerin-bath-body',
    'products/15505/product-catalog/aerin/aerin-rose-collection',
    'products/648/product-catalog/makeup/lips/lip-pencil',
    'products/16758/product-catalog/re-nutriv/re-nutriv-masks-specialists',
    'products/36743/landing-pages/jumbo-sizes',
    'products/29221/landing-pages/mothers-day',
    'products/35377/product-catalog/aerin/aerin-travel-sizes',
    'products/663/product-catalog/Re-Nutriv/Re-Nutriv-collections/more',
    'products/667/product-catalog/re-nutriv/re-nutriv-collections/ultimate-lift-age-correcting',
    'products/12971/product-catalog/re-nutriv/re-nutriv-collections/ultimate-lift-regenerating-youth',
    'products/35805/product-catalog/fragrance/solid-perfumes-compacts',
    'products/647/product-catalog/makeup/lips/lip-gloss',
    'products/12854/product-catalog/fragrance/fragrance-collections/sensuous',
    'products/28394/product-catalog/landing-pages/lunar-new-year',
    'products/1914/product-catalog/landing-pages/pink-ribbon',
    'products/33322/product-catalog/fragrance/fragrance-collections/aramis-for-men',
    'products/35806/product-catalog/fragrance/candles',
    'products/633/product-catalog/makeup/eyes',
    'products/9780/product-catalog/fragrance/fragrance-collections/beautiful',
    'products/707/product-catalog/sets-gifts/skin-care-sets-gifts',
    'products/630/product-catalog/sets-gifts/fragrance-sets-gifts',
    'products/27120/product-catalog/fragrance/bath-body',
    'products/26461/product-catalog/gifts/gifts-over-100',
    'products/26460/product-catalog/gifts/gifts-50-to-100',
    'products/26452/product-catalog/best-sellers/fragrance-best-sellers',
    'products/19239/product-catalog/last-chance',
    'products/19045/product-catalog/sets-gifts',
    'products/12853/product-catalog/fragrance/fragrance-collections/pleasures',
    'products/33199/landing-pages/refillable-beauty-products',
    'products/673/product-catalog/sets-gifts/luxe-sets-gifts',
    'products/34691/product-catalog/fragrance/fragrance-family/woody-earthy',
    'products/34690/product-catalog/fragrance/fragrance-family/warm-spicy',
    'products/34689/product-catalog/fragrance/fragrance-family/rich-floral',
    'products/34688/product-catalog/fragrance/fragrance-family/light-floral',
    'products/26458/product-catalog/sets-gifts/custom-engraving',
    'products/26405/product-catalog/skincare/by-concern/dull-tired-looking-skin',
    'products/26403/product-catalog/skincare/by-concern/pores',
    'products/26402/product-catalog/skincare/by-concern/lines-wrinkles',
    'products/26401/product-catalog/skincare/by-concern/first-signs-of-aging',
    'products/26400/product-catalog/skincare/by-concern/loss-of-firmness',
    'products/26409/product-catalog/makeup/lips/liquid-lip',
    'products/13093/product-catalog/fragrance/fragrance-collections/youth-dew',
    'products/12882/product-catalog/makeup/makeup-remover-tools',
    'products/27121/product-catalog/fragrance/fragrances-perfumes',
    'products/684/product-catalog/skin-care/cleanser-makeup-remover',
    'products/642/product-catalog/makeup/face/concealer',
    'products/637/product-catalog/makeup/eyes/mascara',
    'products/14517/product-catalog/fragrance/fragrance-collections/house-of-estee-lauder',
    'products/687/product-catalog/skin-care/masks',
    'modernizediframe'
  ]
};
